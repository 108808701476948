// plugins/google-tag-manager.js

/* eslint-disable */

export default ({ app,$config }) => {
    // Your GTM container ID
    const gtmId = $config.GOOGLE_TAG_MANAGER;
  
  // Add the GTM script dynamically
  const script = document.createElement('script');
  script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${gtmId}');`;

  // Append the script to the head of the document
  document.head.appendChild(script);

  // Expose the dataLayer globally
  const noscript = document.createElement('noscript');
  const iframe = document.createElement('iframe');
  iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
  iframe.height = '0';
  iframe.width = '0';
  iframe.style.display = 'none';
  iframe.style.visibility = 'hidden';
  noscript.appendChild(iframe);

  document.body.insertBefore(noscript, document.body.firstChild);

  // Expose the dataLayer globally
  window.dataLayer = window.dataLayer || [];
  
};
  